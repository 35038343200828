

export const presaleEnum = [
  {name: '是', value: '1'},
  {name: '否', value: '0'},
]

export const quantityEnum = [
  {name: '是', value: '1'},
  {name: '否', value: '0'},
]

export const afterSalesEnum = [
  {name: '是', value: '1'},
  {name: '否', value: '0'},
]


export default {
  presaleEnum,
  quantityEnum,
  afterSalesEnum,
}