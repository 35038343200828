<template>
  <div class="update-goods">
    <gl-title :title="$route.meta.title" backIcon></gl-title>
    <gl-page-header>
      
    </gl-page-header>
    <div class="form">
      <el-form :model="formData" ref="form" label-width="100px" width="500px" size="mini" v-loading="loading" >
        <el-row>
          <el-col :span="12">
            <el-form-item prop="itemName" label="商品名称" verify>
              <el-input v-model="formData.itemName"></el-input>
            </el-form-item>
            <el-form-item prop="firstCategory" label="商品类目" verify>
              <selectCascader :one.sync="formData.firstCategory" :two.sync="formData.secondCategory" :list="trees"></selectCascader>
            </el-form-item>
            <el-form-item prop="productId" label="商品编号" verify>
              <selected v-model="formData.productId" :productNumber.sync="formData.productNumber"/>
            </el-form-item>

            <el-form-item prop="isPresale" label="是否预售" verify>
              <gl-option v-model="formData.isPresale" :list="presaleEnum"></gl-option>
            </el-form-item>
            <el-form-item prop="presalePrice" label="预售价" verify>
              <el-input v-model="formData.presalePrice"></el-input>
            </el-form-item>
            <el-form-item prop="presaleDate" label="预售日期" verify>
              <el-date-picker
                v-model="formData.presaleDate"
                type="date"
                value-format="yyyy-MM-dd"  
                placeholder="预售日期">
              </el-date-picker>
            </el-form-item>

            <el-form-item prop="isQuantity" label="是否限购" verify>
              <gl-option v-model="formData.isQuantity" :list="quantityEnum"></gl-option>
            </el-form-item>
            <el-form-item prop="quantity" label="限购数量" verify>
              <el-input v-model="formData.quantity"></el-input>
            </el-form-item>
            <el-form-item prop="deliverDate" label="发货时间" verify>
              <el-date-picker
                v-model="formData.deliverDate"
                type="date"
                value-format="yyyy-MM-dd"  
                placeholder="发货时间">
              </el-date-picker>
            </el-form-item>
            <el-form-item prop="isAfterSales" label="是否支持7天无理由" verify>
              <gl-option v-model="formData.isAfterSales" :list="afterSalesEnum"></gl-option>
            </el-form-item>
            <!-- <el-form-item prop="skuSize" label="尺寸" verify>
              <el-input v-model="formData.skuSize"></el-input>
            </el-form-item>
            <el-form-item prop="skuName" label="规格名称" verify>
             <el-input v-model="formData.skuName"></el-input>
            </el-form-item>
            <el-form-item prop="skuPicture" label="商品规格图片" verify>
              <gl-upload-images v-model="formData.skuPicture"></gl-upload-images>
            </el-form-item>
            <el-form-item prop="inventory" label="库存" verify>
              <el-input v-model="formData.inventory"></el-input>
            </el-form-item> -->

            <!-- <el-form-item prop="retailPrice" label="零售单价" verify>
              <el-input v-model="formData.retailPrice"></el-input>
            </el-form-item>
            <el-form-item prop="costPrice" label="出厂价" verify>
              <el-input v-model="formData.costPrice"></el-input>
            </el-form-item> -->
            
            <el-form-item prop="goodsItemPictureVOList" label="商品主图" verify>
              <gl-upload-images v-model="formData.goodsItemPictureVOList"></gl-upload-images>
            </el-form-item>

          </el-col>

          <el-col :span="12">
            <!-- <el-form-item prop="productName" label="商品编号" verify>
              <gl-option></gl-option>
            </el-form-item> -->

            <!-- <el-form-item prop="isPresale" label="是否预售" verify>
              <gl-option v-model="formData.isPresale" :list="presaleEnum"></gl-option>
            </el-form-item>
            <el-form-item prop="presalePrice" label="预售价" verify>
              <el-input v-model="formData.presalePrice"></el-input>
            </el-form-item>
            <el-form-item prop="presaleDate" label="预售日期" verify>
              <el-date-picker
                v-model="formData.presaleDate"
                type="date"
                value-format="yyyy-MM-dd"  
                placeholder="预售日期">
              </el-date-picker>
            </el-form-item> -->

            <el-form-item prop="skuSize" label="尺寸" verify>
              <el-input v-model="formData.skuSize"></el-input>
            </el-form-item>
            <el-form-item prop="skuName" label="规格名称" verify>
              <el-input v-model="formData.skuName"></el-input>
            </el-form-item>
            <el-form-item prop="inventory" label="库存" verify>
              <el-input v-model="formData.inventory"></el-input>
            </el-form-item>
             <el-form-item prop="retailPrice" label="零售单价" verify>
              <el-input v-model="formData.retailPrice"></el-input>
            </el-form-item>
            <el-form-item prop="costPrice" label="出厂价" verify>
              <el-input v-model="formData.costPrice"></el-input>
            </el-form-item>

            <el-form-item prop="skuPicture" label="商品规格图片" verify>
              <gl-upload-images v-model="formData.skuPicture" :limit="2"></gl-upload-images>
            </el-form-item>
            <!-- <el-form-item prop="skuPicture" label="商品规格图片" verify>
              <gl-upload-image ref="uploadImage" :limit="2"></gl-upload-image>
            </el-form-item> -->

          </el-col>
        </el-row>

        <!-- <el-form-item prop="itemDetail" label="商品详情" verify>
          <gl-editor v-model="formData.itemDetail"></gl-editor>
        </el-form-item>
        <el-form-item prop="goodsSkuVOList" label="商品规格" verify>
          <selected />
        </el-form-item> -->

      </el-form> 
      <div v-loading="loading" style="padding: 10px;">
        <el-table :data="resList" style="width: 100%">
          <el-table-column prop="itemId" label="产品ID" width="300">
            <template slot="header">
              <div class="mytable-header" style="display: flex;align-items: center;">
                <span>4444</span> 
                <span>
                  <!-- <el-input size="mini"></el-input> -->
                  <gl-option :list="[{name:'选项1',value:'1'},{name:'选项2',value:'2'}]"></gl-option>
                </span>
                <span> 8520 </span>
              </div>
            </template>
            <template slot-scope="{row}">
              <span> {{row.name}} </span>
            </template>
          </el-table-column>
          <el-table-column prop="itemName" label="产品名称">
            <template slot="header">
              <div class="mytable-header">
                <span>id</span>
              </div>
            </template>
            <template slot-scope="{row}">
              <span> {{row.id}} </span>
            </template>
          </el-table-column>
          <el-table-column prop="type" label="产品名称">
            <template slot="header">
              <div class="mytable-header">
                <span>type</span>
              </div>
            </template>
            <template slot-scope="{row}">
              <span> {{row.type}} </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <gl-flex>
        <el-button type="primary" @click="onSubmit" >确认</el-button>
        <el-button @click="$router.go(-1)" >取消</el-button>
      </gl-flex>
    </div>
  </div>
</template>

<script>
import {
  presaleEnum,
  quantityEnum,
  afterSalesEnum
} from './components/enum'
import {getTree} from '@/api/merchant/goods'
import selected from './components/selected.vue'
import selectCascader from './components/selectCascader.vue'

export default {
  components: {selected, selectCascader},
  data(){
    return{
      formData: {},
      loading: false,
      trees: [],
      presaleEnum: presaleEnum,
      quantityEnum: quantityEnum,
      afterSalesEnum: afterSalesEnum,
      resList: [
        {name: 'evan', id: 'idddd', type: 'type'},
      ],
      params: {
        pageNum: 1,
        pageSize: 10
      },
      goodsSkuVOList: [],
    }
  },
  mounted(){
    this.getTrees()
  },
  methods: {
    onSubmit(){
      console.log('formData:', this.formData)
      const obj = {
        name: 'evan1',
        id: 'id8888888888',
        type: 'type'
      }
      this.resList.push(obj)

      // this.$refs.uploadImage.upload().then(res => {
      //   console.error('res:', res)
      // })
    },
    
    async getTrees(){
      const res = await getTree()
      this.trees = res.data
    },
  },
}
</script>

<style lang="scss" scoped>
.update-goods{
  .form{
    margin: 20px;
    padding: 20px;
    background-color: #fff;
    border-radius: 4px;
  }
}
</style>

<style lang="scss">
.update-goods{
  // .form{
  //   .el-input{
  //     // width: 300px;
  //   }
  // }
  .el-table{
    .el-table__header {
      height: 50px !important;

      th {
          padding: 0 0 !important;
          background-color: #ffffff;
          // background-color: #EEF2FE !important;
          .cell {
              height: 38px;
              line-height: 38px;
              // border: 1px solid red;
          }
      }
    }
  }
  .mytable-header{
    display: flex;
    align-content: center;
    //选项组件覆盖
    .el-input__inner{
      padding: 0 !important;
      width: 100px !important;
    }
  }
  
}
</style>