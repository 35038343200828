<template>
  <div class="selected">
    <el-select 
      class="selected"
      v-model="selectValue" 
      :disabled="disabled"
      placeholder="请选择"
      filterable
      reserve-keyword
      remote
      :remote-method="remoteMethod"
      @change="change"
      v-el-select-loadmore="loadMore"
      :loading="loading">
        <el-option
        v-for="item in options"
        :key="item.productNumber"
        :label="item.productNumber"
        :value="item.productNumber">
        </el-option>
    </el-select>
    <!-- :value="item.productId" :label="item.productName" :key="item.productId" :value-key="item.productNumber"-->
  </div>
</template>

<script>
import {getProductList} from '@/api/merchant/goods'
import {debounce} from '@/utils'
export default {
  name: 'selected',
  props: {
    value: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return{
      selectList: [],
      params: {
        pageNum: 1,
        pageSize: 10,
        productNumber: '',
      },
      selectValue: '',
      loading: false,
      total: 0,
    }
  },
  computed: {
    options(){
      return this.selectList
    },
  },
  watch: {
    value: {
      handler: function(newVal){
        this.selectValue = newVal
      }
    }
  },
  created(){
    this.loadList()
  },
  methods: {
    remoteMethod(val){
      this.params.productNumber = val
      const reload = debounce(this.reload, 600)
      reload()
    },
    reload(){
      this.params.pageNum = 1
      this.selectList.length = 0
      this.loadList()
    },
    async loadList(){
      this.loading = true
      const res = await getProductList(this.params)
      const newArr = res.data.rows.map((item,index) => {
        return {
          ...item,
          index: index.toString()
        }
      })
      this.selectList = this.selectList.concat(newArr)
      this.total = Number(res.data.total)
      this.loading = false
    },
    change(val){
      const itemsObj = this.options.find(item => item.productNumber == val)
      this.selectValue = itemsObj.productNumber
      this.$emit('input', itemsObj.productNumber)
      this.$emit('change', itemsObj.productNumber)
      this.$emit('update:productId', itemsObj.productId)
      this.$emit('update:productNumber', itemsObj.productNumber)
    },
    loadMore(){
      if(this.selectList.length >= this.total) return
      this.params.pageNum ++
      this.loadList()
    },
  },
  directives: {//注意：这个是写到data外面
    'el-select-loadmore': {
      bind (el, binding) {
        const SELECTWRAP_DOM = el.querySelector(
          '.el-select-dropdown .el-select-dropdown__wrap'
        )
        SELECTWRAP_DOM.addEventListener('scroll', function () {
          const condition = this.scrollHeight - this.scrollTop <= this.clientHeight
          if (condition) {
            // throttle(binding.value(), 5000)
            binding.value()
          }
        })
      }
    }
  },
}
</script>

<style lang="scss">
.selected{
  .el-select {
      /* display: inline-block; */
      display: block !important;
      position: relative;
  }
}
  
</style>